import { createAsyncThunk } from '@reduxjs/toolkit'
import { IPagination } from '../../../utility/interfaces/pagination.interface';
import { listSellerPaymentsApi } from '../services/payments.service';

// Admin Actions
export const listSellerPaymentsAction = createAsyncThunk('listSellerPaymentsAction/get', async (data: IPagination, thunkApi) => {
	try {
		const response = await listSellerPaymentsApi(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
