import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { createFirebaseTokenAction, signupAction } from '../action/auth.action'
import { useTranslation } from 'react-i18next'
import { validate } from '../../../utility/helpers/form-validation.helper'
import { getAllCitiesAction, getAllGovernoratesAction } from '../../../common/lookups/actions/lookup.action'
import { Translation } from '../../../utility/helpers/translation.helper'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_green.css'
import Multiselect from 'multiselect-react-dropdown'
import { addKitchenAction } from '../../kitchen/actions/kitchen.actions'
import { IKitchen } from '../../kitchen/interface/kitchen.interface'
import { GoogleMapsComponent } from '../../../components/google-maps/GoogleMaps.component'
import { getSellerCategoriesAction } from '../../categories/action/Category.action'
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import { getProfileAction } from '../../profile/action/profile.action'
import { requestForToken } from '../../../firebase';

const SignupPage: React.FC<any> = () => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const { governorates, cities } = useAppSelector((state) => state.lookup)
  const { categories, status } = useAppSelector((state) => state.category)
  const [owner, setOwner] = useState(window.localStorage.getItem('Owner') ?? null)
  const [activeTab, setActiveTab] = useState(JSON.parse(window.localStorage.getItem('StepNum')!) ?? 1)
	const [token, setToken] = useState("");
  const [weekDays] = useState([
    { En: 'Saturday', Ar: 'السبت' },
    { En: 'Sunday', Ar: 'اﻷحد' },
    { En: 'Monday', Ar: 'اﻷثنين' },
    { En: 'Tuesday', Ar: 'الثلاثاء' },
    { En: 'Wednesday', Ar: 'اﻷربعاء' },
    { En: 'Thursday', Ar: 'الخميس' },
    { En: 'Friday', Ar: 'الجمعه' }
  ])
  const [userForm, setUserForm] = useState({
    firstName: null,
    lastName: null,
    email: null,
    mobileNumber: null,
    role: 'SELLER',
    password: null,
    confirmPassword: null,
    gender: 'Female'
  })

  const [isEqual, setIsEqual] = useState(true)

  const [catData, setCatData] = useState([] as any)
  const [kitchenForm, setKitchenForm] = useState({} as IKitchen)
  const [location, setLocation] = useState({
    type: 'Point',
    coordinates: [30.0443953, 31.233081 ]
  })
	
	useEffect(() => {
		const getToken = async () => {
			const permission = await Notification.requestPermission();
			if (permission === "granted") {
				const token = await requestForToken();
				if (token) {
					setToken(token);
				}
			}
		};
		getToken();
	}, []);

  useEffect(() => {
    if (!categories || status == REDUX_STATUS.IDLE) {
      dispatch(
        getSellerCategoriesAction({
          offset: 1,
          limit: 12,
          order: {
            orderBy: 'createdAt',
            orderDirection: SORT_DIRECTION.ASC
          }
        })
      )
    }
  }, [dispatch, categories, status])

  useEffect(() => {
    if (categories) {
      const newCats = categories.map((cat) => {
        return {
          id: cat._id,
          En: cat.name.En,
          Ar: cat.name.Ar
        }
      })
      setCatData(newCats)
    }
  }, [categories])

  useEffect(() => {
    if (!governorates) {
      dispatch(getAllGovernoratesAction())
    }
  }, [dispatch, governorates])

  const nextTab = (tabNumber: number) => {
    validate(document.getElementsByClassName('needs-validation')[tabNumber - 2] as HTMLFormElement).then((validate) => {
      if (validate) {
        switch (tabNumber) {
          case 2:
            userSubmitHandler(tabNumber)
            return
          case 3:
            kitchenSubmitHandler(tabNumber)
            return
          default:
            setActiveTab(1)
        }
      }
    })
  }

  const comparePasswords = (pass: string | null, confirmPass: string | null): boolean => {
    if (pass !== confirmPass) {
      setIsEqual(false)
      return false
    } else {
      return true
    }
  }

  const userChangeHandler = (event: any) => {
    const { name, value } = event.target
    setUserForm((prevFormData) => ({ ...prevFormData, [name]: value }))
  }

  const kitchenChangeHandler = (event: any) => {
    const { name, value } = event.target
    switch (name) {
      case 'categories': {
        setKitchenForm((prevFormData) => ({
          ...prevFormData,
          [name]: value.map((val: any) => {
            return val.id
          })
        }))
        break
      }
      case 'nameEn': {
        setKitchenForm((prevFormData) => ({ ...prevFormData, name: { ...prevFormData.name, En: value } }))
        break
      }
      case 'nameAr': {
        setKitchenForm((prevFormData) => ({ ...prevFormData, name: { ...prevFormData.name, Ar: value } }))
        break
      }
      case 'descriptionEn': {
        setKitchenForm((prevFormData) => ({ ...prevFormData, description: { ...prevFormData.description, En: value } }))
        break
      }
      case 'descriptionAr': {
        setKitchenForm((prevFormData) => ({ ...prevFormData, description: { ...prevFormData.description, Ar: value } }))
        break
      }
      case 'governorate': {
        dispatch(getAllCitiesAction(value))
        setKitchenForm((prevFormData) => ({ ...prevFormData, governorate: value }))
        break
      }
      default: {
        setKitchenForm((prevFormData) => ({ ...prevFormData, [name]: value }))
        break
      }
    }
  }

  const userSubmitHandler = async (tabNumber: number) => {
    const isEqual: boolean = await comparePasswords(userForm.password, userForm.confirmPassword)
    if (isEqual) {
      dispatch(signupAction(userForm)).then((res: any) => {
        if (res.payload.success) {
					dispatch(createFirebaseTokenAction(token))
          setActiveTab(tabNumber)
          window.localStorage.setItem('StepNum', JSON.stringify(tabNumber))
          window.localStorage.setItem('Owner', res.payload.data.id)
          dispatch(getProfileAction())
          setOwner(res.payload.data.id)
        }
      })
    }
  }

  const kitchenSubmitHandler = (tabNumber: number) => {
    dispatch(
      addKitchenAction({
        ...kitchenForm,
        owner,
        openTime: Date.parse(kitchenForm.openTime.toString()),
        closeTime: Date.parse(kitchenForm.closeTime.toString()),
        location
      })
    ).then((res: any) => {
      if (res.payload.success) {
        setActiveTab(tabNumber)
        window.localStorage.removeItem('StepNum')
        window.localStorage.removeItem('Owner')
      }
    })
  }

  return (
    <div className="auth-page-wrapper pt-5">
      <Helmet>
        <script src="/assets/js/pages/particles.app.js" async></script>
        <script src="/assets/js/pages/password-addon.init.js" async></script>
      </Helmet>
      <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div className="bg-overlay"></div>

        <div className="shape">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
          </svg>
        </div>
      </div>

      <div className="auth-page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to={'/'} className="d-inline-block auth-logo">
                    <img src="/assets/images/logos/akla-beity-logoline.png" alt="akla beity logo" height="60" />
                  </Link>
                </div>
                <p className="mt-3 fs-15 fw-medium">{t('Intro')}</p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="step-arrow-nav mb-4">
                    <ul className="nav nav-pills custom-nav nav-justified" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className={activeTab === 1 ? 'nav-link active' : 'nav-link'}
                          id="userInfo-tab"
                          type="button"
                          role="tab"
                          aria-controls="userInfo"
                          aria-selected="true">
                          {t('UserInfo')}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={activeTab === 2 ? 'nav-link active' : 'nav-link'}
                          id="kitchenInfo-tab"
                          type="button"
                          role="tab"
                          aria-controls="kitchenInfo"
                          aria-selected="false">
                          {t('KitchenInfo')}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={activeTab === 3 ? 'nav-link active' : 'nav-link'}
                          id="finish-tab"
                          type="button"
                          role="tab"
                          aria-controls="finish"
                          aria-selected="false">
                          {t('Finish')}
                        </button>
                      </li>
                    </ul>
                  </div>

                  <div className="tab-content">
                    <div
                      className={activeTab === 1 ? 'tab-pane fade show active' : 'tab-pane fade'}
                      id="userInfo"
                      role="tabpanel"
                      aria-labelledby="userInfo-tab">
                      <form className="form-steps needs-validation" autoComplete="off">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="firstName">
                                {t('FirstName')}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="firstName"
                                name="firstName"
                                placeholder={t('FirstName')}
                                onChange={userChangeHandler}
                                minLength={2}
                                required
                              />
                              <div className="invalid-feedback">{t('NameError')}</div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="lastName">
                                {t('LastName')}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                name="lastName"
                                placeholder={t('LastName')}
                                minLength={2}
                                onChange={userChangeHandler}
                                required
                              />
                              <div className="invalid-feedback">{t('NameError')}</div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="email">
                                {t('EmailAddress')}
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder={t('EmailAddress')}
                                onChange={userChangeHandler}
                                required
                              />
                              <div className="invalid-feedback">{t('Email')}</div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="mobileNumber">
                                {t('MobileNumber')}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="mobileNumber"
                                name="mobileNumber"
                                placeholder={t('MobileNumber')}
                                minLength={11}
                                maxLength={11}
                                onChange={userChangeHandler}
                                required
                              />
                              <div className="invalid-feedback">{t('MobileNumberError')}</div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="password">
                                {t('Password')}
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                placeholder={t('Password')}
                                onChange={userChangeHandler}
                                required
                                minLength={8}
                              />
                              <div className="invalid-feedback">{t('PasswordError')}</div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="confirmPassword">
                                {t('ConfirmPassword')}
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="confirmPassword"
                                placeholder={t('ConfirmPassword')}
                                required
                                minLength={8}
                                name="confirmPassword"
                                onChange={userChangeHandler}
                              />
                              <div className="invalid-feedback">{t('ConfirmPasswordError')}</div>
                              {!isEqual && <div className="invalid-password">{t('PasswordNotEqual')}</div>}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="form-label" htmlFor="gender">
                              {t('Gender')}
                            </label>
                            <br />
                            <div className="form-check-inline mb-2">
                              <input className="form-check-input" type="radio" value="female" name="gender" id="female" onChange={userChangeHandler} />
                              <label className="form-check-label ms-2" htmlFor="female">
                                {t('Female')}
                              </label>
                            </div>
                            <div className="form-check-inline mb-2">
                              <input className="form-check-input" type="radio" value="male" name="gender" id="male" onChange={userChangeHandler} />
                              <label className="form-check-label ms-2" htmlFor="male">
                                {t('Male')}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-start gap-3 mt-4">
                          <button type="button" className="btn btn-success btn-label right ms-auto nexttab nexttab" onClick={() => nextTab(2)}>
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            {t('NextStep')}
                          </button>
                        </div>
                      </form>
                    </div>

                    <div
                      className={activeTab === 2 ? 'tab-pane fade show active' : 'tab-pane fade'}
                      id="kitchenInfo"
                      role="tabpanel"
                      aria-labelledby="kitchenInfo-tab">
                      <form className="form-steps needs-validation" autoComplete="off">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="nameEn">
                                {t('KitchenNameEn')}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="nameEn"
                                name="nameEn"
                                placeholder={t('KitchenNameEn')}
                                onChange={kitchenChangeHandler}
                                minLength={2}
                                required
                              />
                              <div className="invalid-feedback">{t('KitchenName')}</div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="nameAr">
                                {t('KitchenNameAr')}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="nameAr"
                                name="nameAr"
                                placeholder={t('KitchenNameAr')}
                                minLength={2}
                                onChange={kitchenChangeHandler}
                                required
                              />
                              <div className="invalid-feedback">{t('KitchenName')}</div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="governorate">
                                {t('Governorate')}
                              </label>
                              <select
                                className="form-select"
                                defaultValue={'DEFAULT'}
                                id="governorate"
                                name="governorate"
                                onChange={kitchenChangeHandler}
                                required>
                                <option disabled value="DEFAULT">
                                  {t('Governorate')}
                                </option>
                                {governorates?.map((govern) => (
                                  <option key={govern._id} value={govern._id}>
                                    {Translation(govern.name)}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="city">
                                {t('City')}
                              </label>
                              <select
                                className="form-select"
                                defaultValue={'DEFAULT'}
                                id="city"
                                name="city"
                                onChange={kitchenChangeHandler}
                                required
                                disabled={!kitchenForm.governorate}>
                                <option disabled value="DEFAULT">
                                  {t('City')}
                                </option>
                                {cities?.map((city) => (
                                  <option key={city._id} value={city._id}>
                                    {Translation(city.name)}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="form-label" htmlFor="delivaryCharge">
                              {t('DelivaryCharges')}
                            </label>
                            <div className="input-group">
                              <input
                                type="number"
                                className="form-control"
                                name="delivaryCharge"
                                id="delivaryCharge"
                                placeholder={t('DelivaryCharges')}
                                onChange={kitchenChangeHandler}
                                min={0}
                                required
                              />
                              <span className="input-group-text">{t("CurrencyCode")}</span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-4">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="openTime">
                                {t('OpenTime')}
                              </label>
                              <Flatpickr
                                className="form-control"
                                data-enable-time
                                data-no-calendar
                                value={kitchenForm.openTime}
                                onChange={([date]: any) => {
                                  kitchenChangeHandler({ target: { name: 'openTime', value: date } })
                                }}
                              />
                              <div className="invalid-feedback">{t('Time')}</div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="closeTime">
                                {t('CloseTime')}
                              </label>
                              <Flatpickr
                                className="form-control"
                                data-enable-time
                                data-no-calendar
                                value={kitchenForm.closeTime}
                                onChange={([date]: any) => {
                                  kitchenChangeHandler({ target: { name: 'closeTime', value: date } })
                                }}
                              />
                              <div className="invalid-feedback">{t('Time')}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="form-label" htmlFor="isActive">
                              {t('IsActive')}
                            </label>
                            <br />
                            <div className="form-check-inline mb-2">
                              <input className="form-check-input" type="radio" value={1} name="isActive" id="active" onChange={kitchenChangeHandler} />
                              <label className="form-check-label ms-2" htmlFor="active">
                                {t('Active')}
                              </label>
                            </div>
                            <div className="form-check-inline mb-2">
                              <input className="form-check-input" type="radio" value={0} name="isActive" id="inactive" onChange={kitchenChangeHandler} />
                              <label className="form-check-label ms-2" htmlFor="inactive">
                                {t('InActive')}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <label className="form-label" htmlFor="descriptionEn">
                              {t('DescriptionEn')}
                            </label>
                            <textarea
                              className="form-control"
                              placeholder={t('DescriptionEn')}
                              id="descriptionEn"
                              name="descriptionEn"
                              onChange={kitchenChangeHandler}
                              rows={3}
                              required></textarea>
                            <div className="invalid-feedback">{t('DescriptionError')}</div>
                          </div>
                          <div className="col-md-6">
                            <label className="form-label" htmlFor="descriptionAr">
                              {t('DescriptionAr')}
                            </label>
                            <textarea
                              className="form-control"
                              placeholder={t('DescriptionAr')}
                              id="descriptionAr"
                              name="descriptionAr"
                              onChange={kitchenChangeHandler}
                              rows={3}
                              required></textarea>
                            <div className="invalid-feedback">{t('DescriptionError')}</div>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-md-6">
                            <label className="form-label" htmlFor="categories">
                              {t('Categories')}
                            </label>
                            <Multiselect
                              id="categories"
                              options={catData}
                              placeholder={t('Categories')}
                              onSelect={(e) => kitchenChangeHandler({ target: { name: 'categories', value: e } })}
                              onRemove={(e) => kitchenChangeHandler({ target: { name: 'categories', value: e } })}
                              displayValue={i18n.language === 'En' ? 'En' : 'Ar'}
                            />
                          </div>
                          <div className="col-md-6">
                            <label className="form-label" htmlFor="workingDays">
                              {t('WorkingDays')}
                            </label>
                            <Multiselect
                              id="workingDays"
                              options={weekDays}
                              placeholder={t('WorkingDays')}
                              onSelect={(e) => kitchenChangeHandler({ target: { name: 'workingDays', value: e } })}
                              onRemove={(e) => kitchenChangeHandler({ target: { name: 'workingDays', value: e } })}
                              displayValue={i18n.language === 'En' ? 'En' : 'Ar'}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <label className="form-label" htmlFor="address">
                              {t('Address')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              name="address"
                              placeholder={t('Address')}
                              onChange={kitchenChangeHandler}
                              minLength={2}
                              required
                            />
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-md-12">
                            <GoogleMapsComponent setUserLocation={setLocation} />
                          </div>
                        </div>

                        <div className="d-flex align-items-start gap-3 mt-4">
                          {/* <button type="button" className="btn btn-light btn-label previestab" onClick={() => setActiveTab(1)}>
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> {t('GoBack')}
                          </button> */}
                          <button type="button" className="btn btn-success btn-label right ms-auto nexttab nexttab" onClick={() => nextTab(3)}>
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            {t('Submit')}
                          </button>
                        </div>
                      </form>
                    </div>

                    <div className={activeTab === 3 ? 'tab-pane fade show active' : 'tab-pane fade'} id="finish" role="tabpanel">
                      <div className="text-center">
                        <div className="avatar-md mt-5 mb-4 mx-auto">
                          <div className="avatar-title bg-light text-success display-4 rounded-circle">
                            <i className="ri-checkbox-circle-fill"></i>
                          </div>
                        </div>
                        <h5>{t('WellDone')}</h5>
                        <p className="text-muted">{t('SignupSuccess')}</p>
                        <Link to={'/'} type="button" className="btn btn-primary">
                          <i className="ri-home-line me-1"></i> {t('Dashboard')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <p className="mb-0">
                  {t('Have_Account')} 
                  <Link to="/auth/login" className="fw-semibold text-primary text-decoration-underline mx-1">
                    {t('Sign_In')}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupPage
