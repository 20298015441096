import { _ } from 'gridjs-react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import GridjsComponent from '../../../components/gridjs/Gridjs.component';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import { listSellerPaymentsAction } from '../actions/payments.actions';
import { useLoading } from '../../../provider/IsLoading.provider';
import { IPayment } from '../interfaces/payments.interface';
import { useNavigate } from 'react-router-dom';
import WarningComponent from '../../../components/Confirmation-modals/warning.component';

export const PaymentsListPage: React.FC<any> = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { payments, status } = useAppSelector((state) => state.payment);
	const { setLoading } = useLoading();
	const navigate = useNavigate();

	const [totalEarning, setTotalEarning] = useState(0);
	const [remainingBalance, setRemainingBalance] = useState(0);
	const [paidBalance, setPaidBalance] = useState(0);
	const modalClose = useRef<HTMLButtonElement>(null);

	const breadcrumb: Breadcrumb = {
		title: t('Payments'),
		links: [
			{ name: t('Dashboard'), path: '/' },
			{ name: t('Payments'), path: '/payments/list' }
		]
	};

	const payment_columns = [
		{
			name: t('OrderId'),
			width: '250px',
			sort: true,
			data: (e: IPayment) => {
				return _(
					<div className="d-flex align-items-center">
						<div className="flex-grow-1">
							<p className="text-muted mb-0">
								<a role="button" onClick={() => navigateToDetails('/orders/details/' + e.order)} className="text-body">
									{e.order}
								</a>
							</p>
						</div>
					</div>
				);
			}
		},
		{
			name: t('PaymentMethod'),
			sort: true,
			width: '180px',
			data: (e: IPayment) => {
				return _(t(`${e.paymentMethod}`));
			}
		},
		{
			name: t('IsActive'),
			sort: true,
			width: '120px',
			data: (e: IPayment) => {
				return _(
					e.isActive ? (
						<span className="badge bg-success-subtle text-success text-uppercase">{t('Active')}</span>
					) : (
						<span className="badge bg-danger-subtle text-danger text-uppercase">{t('InActive')}</span>
					)
				);
			}
		},
		{
			name: t('ServiceFee'),
			sort: true,
			width: '120px',
			data: (e: IPayment) => {
				return _(e.serviceFee + " " + t('CurrencyCode'));
			}
		},
		{
			name: t('Total'),
			sort: true,
			width: '100px',
			data: (e: IPayment) => {
				return _(e.total + " " + t('CurrencyCode'));
			}
		},
		{
			name: t('Status'),
			sort: true,
			width: '100px',
			data: (e: IPayment) => {
				return _(t(e.status));
			}
		},
		{
			name: t('Actions'),
			width: '110px',
			data: (e: IPayment) => {
				return _(
					<ul className="list-inline hstack gap-2 mb-0">
						<li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
							<a role="button" className="text-body">
								<i className="ri-eye-fill fs-16"></i>
							</a>
						</li>
					</ul>
				);
			}
		}
	];

	useEffect(() => {
		if (status == REDUX_STATUS.IDLE) {
			setLoading(true);
			dispatch(
				listSellerPaymentsAction({
					page: 1,
					perPage: 12,
					orderBy: 'createdAt',
					orderDirection: SORT_DIRECTION.ASC
				})
			);
		}
	}, [dispatch, status, setLoading]);

	useEffect(() => {
		if (status == REDUX_STATUS.SUCCEEDED || status == REDUX_STATUS.FAILED) {
			setLoading(false);
		}
		calcTotalOrders(payments);
		calcRemainingBalance(payments);
		calcPaidBalance(payments);
	}, [payments, setLoading, status]);

	const calcTotalOrders = async (payments: Array<IPayment>) => {
		let total = 0;
		await payments.map((bill: IPayment) => {
			console.log(bill.total, payments);
			total += bill.total;
		});
		setTotalEarning(total);
	};

	const calcRemainingBalance = (payments: Array<IPayment>) => {
		let remaining = 0;
		payments.map((bill: IPayment) => {
			console.log(bill.paidOnline);
			remaining += bill.paidOnline;
		});
		setRemainingBalance(remaining);
	};

	const calcPaidBalance = (payments: Array<IPayment>) => {
		let paid = 0;
		payments.map((bill: IPayment) => {
			console.log(bill.paidCash);
			paid += bill.paidCash;
		});
		setPaidBalance(paid);
	};

	const navigateToDetails = (navLink: string) => {
		navigate(navLink);
	};

	const withdrawHandler = () => {
		console.log("Withdraw");
		modalClose.current!.click();
	};

	return (
		<div className="container-fluid">
			<WarningComponent actionHandler={withdrawHandler} message={t("Are_You_Sure_Withdraw")} />
			<div className="row">
				<BreadcrumbComponent breadcrumb={breadcrumb} />
			</div>

			<div className="row">
				<div className="col-xl-3 col-md-6">
					<div className="card card-animate">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1 overflow-hidden">
									<p className="text-uppercase fw-medium text-muted text-truncate mb-0"> {t("Total_Earnings")}</p>
								</div>
								<div className="flex-shrink-0">
									<span className="avatar-title bg-success-subtle rounded fs-3">
										<i className="bx bx-dollar-circle text-success"></i>
									</span>
								</div>
							</div>
							<div className="d-flex align-items-end justify-content-between mt-4">
								<div>
									<h4 className="fs-22 fw-semibold ff-secondary">
										<span className="counter-value" data-target={totalEarning}>
											{totalEarning} {t('CurrencyCode')}
										</span>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6">
					<div className="card card-animate">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1 overflow-hidden">
									<p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Bills')}</p>
								</div>
								<div className="flex-shrink-0">
									<span className="avatar-title bg-info-subtle rounded fs-3">
										<i className="bx bx-shopping-bag text-info"></i>
									</span>
								</div>
							</div>
							<div className="d-flex align-items-end justify-content-between mt-4">
								<div>
									<h4 className="fs-22 fw-semibold ff-secondary">
										<span className="counter-value" data-target={payments.length}>
											{payments.length} {t('Orders')}
										</span>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6">
					<div className="card card-animate">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1 overflow-hidden">
									<p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t("Paid_Balance")}</p>
								</div>
								<div className="flex-shrink-0">
									<span className="avatar-title bg-warning-subtle rounded fs-3">
										<i className="bx bx-user-circle text-warning"></i>
									</span>
								</div>
							</div>
							<div className="d-flex align-items-end justify-content-between mt-4">
								<div>
									<h4 className="fs-22 fw-semibold ff-secondary">
										<span className="counter-value" data-target={paidBalance}>
											{paidBalance} {t('CurrencyCode')}
										</span>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6">
					<div className="card card-animate">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1 overflow-hidden">
									<p className="text-uppercase fw-medium text-muted text-truncate mb-0"> {t("Remaining_Balance")}</p>
								</div>
								<div className="flex-shrink-0">
									<span className="avatar-title bg-primary-subtle rounded fs-3">
										<i className="bx bx-wallet text-primary"></i>
									</span>
								</div>
							</div>
							<div className="d-flex align-items-end justify-content-between mt-4">
								<h4 className="fs-22 fw-semibold ff-secondary d-flex justify-content-between">
									<span className="counter-value" data-target={remainingBalance}>
										{remainingBalance} {t('CurrencyCode')}
									</span>
								</h4>
								{remainingBalance > 1000 &&
									<button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#warningModal">{t("Withdraw")}</button>
								}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-12">
					<div>
						<div className="card">
							<div className="card-header border-0">
								<div className="row g-4">
									<div className="col-sm">
										<div className="d-flex justify-content-sm-end">
											<div className="search-box ms-2">
												<input type="text" className="form-control bg-light border-0" id="searchProductList" placeholder={t("Search_Placeholder")} />
												<i className="ri-search-line search-icon"></i>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="card-body">
								<div className="tab-content text-muted">
									<div id="table-product-list-all" className="table-card gridjs-border-none">
										<GridjsComponent columns={payment_columns} data={payments} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div id="withdrawModal" className="modal fade" tabIndex={-1} aria-labelledby="withdrawModalLabel" aria-hidden="true" style={{ display: 'none' }}>
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={modalClose}></button>
						</div>
						<div className="modal-body">
							<div className="row">

							</div>
						</div>
					</div>
				</div>
			</div> */}

		</div>
	);
};
