import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component'
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { getOrderDetailsAction, updateOrderStatusAction } from '../actions/orders.actions'
import { Translation } from '../../../utility/helpers/translation.helper'
import { ORDER_STATUS } from '../../../utility/enums/order-status.enum'
import { SpecialOrderTableComponent } from '../components/special-order-table.component'
import { NormalOrderTableComponent } from '../components/normal-order-table.component'

export const OrdersDetailsPage: React.FC<any> = () => {
	const assets = process.env.REACT_APP_ASSETS_URL
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { details } = useAppSelector((state) => state.order)
  const params = useParams()

  const breadcrumb: Breadcrumb = {
    title: t('Orders'),
    links: [
      { name: t('Dashboard'), path: '/' },
      { name: t('Orders'), path: '/orders/list/' },
      { name: t('Details'), path: '/orders/details/' + params.id }
    ]
  }

  useEffect(() => {
    dispatch(getOrderDetailsAction(params.id!))
  }, [dispatch, params])

  function handleUpdateOrderStatus(status: ORDER_STATUS) {
    dispatch(updateOrderStatusAction({ id: details._id, status }))
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <BreadcrumbComponent breadcrumb={breadcrumb} />
      </div>

      <div className="row">
        <div className="col-xl-8">
          <div className="card">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <h5 className="card-title flex-grow-1 mb-0">
                  {t('Order')} #{details._id}
                </h5>
                <div className="dropdown ml-2">
                  <button
                    className="btn btn-sm btn-success dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    disabled={!details.isActive}
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {t('' + details.status)}
                  </button>
                  <div className="dropdown-menu " aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item cursor-pointer" onClick={() => handleUpdateOrderStatus(ORDER_STATUS.PENDING)} aria-disabled>
                      {ORDER_STATUS.PENDING}
                    </a>
                    <a className="dropdown-item cursor-pointer" onClick={() => handleUpdateOrderStatus(ORDER_STATUS.APPROVED)}>
                      {ORDER_STATUS.APPROVED}
                    </a>
                    <a className="dropdown-item cursor-pointer" onClick={() => handleUpdateOrderStatus(ORDER_STATUS.DELIVERING)}>
                      {ORDER_STATUS.DELIVERING}
                    </a>
                    <a className="dropdown-item cursor-pointer" onClick={() => handleUpdateOrderStatus(ORDER_STATUS.FULLFILLED)}>
                      {ORDER_STATUS.FULLFILLED}
                    </a>
                    <a className="dropdown-item cursor-pointer" onClick={() => handleUpdateOrderStatus(ORDER_STATUS.CANCELED)}>
                      {ORDER_STATUS.CANCELED}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive table-card">
                {!details.specialOrder && <NormalOrderTableComponent details={details}></NormalOrderTableComponent>}
                {details.specialOrder && <SpecialOrderTableComponent details={details}></SpecialOrderTableComponent>}
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="card">
            <div className="card-header">
              <div className="d-flex">
                <h5 className="card-title flex-grow-1 mb-0">{t('CustomerDetails')}</h5>
              </div>
            </div>
            <div className="card-body">
              <ul className="list-unstyled mb-0 vstack gap-3">
                <li>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img src={assets + details.customer?.photo} alt="" className="avatar-sm rounded" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="text-muted mb-0">{t('CustomerDetails')}</p>
                      <h6 className="fs-14 mb-1">
                        {details.customer?.firstName} {details.customer?.lastName}
                      </h6>

                      <h6 className="text-muted">{details.customer?.mobileNumber}</h6>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">
                <i className="ri-map-pin-line align-middle me-1 text-muted"></i> {t('DelivaryAddress')}
              </h5>
            </div>
            <div className="card-body">
              <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                <li className="fw-medium fs-14">
                  {t('Governerate')} : {Translation(details.address?.governorate.name)}
                </li>
                <li>
                  {t('City')} : {Translation(details.address?.city?.name)}
                </li>
                <li>
                  {t('Street')} : {details.address?.street}
                </li>
                <li>
                  {t('BuildingNumber')} : {details.address?.buildingNumber}
                </li>
                <li>
                  {t('Floor')} : {details.address?.floor}
                </li>
              </ul>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">
                <i className="ri-secure-payment-line align-bottom me-1 text-muted"></i> {t('PaymentDetails')}
              </h5>
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="flex-shrink-0">
                  <p className="text-muted mb-0">{t('Paid')}:</p>
                </div>
                <div className="flex-grow-1 ms-2">
                  <h6 className="mb-0">
                    {details.paymentInfo?.totalPrice - details.paymentInfo?.remainingAmount} {t('CurrencyCode')}
                  </h6>
                </div>
              </div>
              <div className="d-flex align-items-center mb-2">
                <div className="flex-shrink-0">
                  <p className="text-muted mb-0">{t('Remaining')}:</p>
                </div>
                <div className="flex-grow-1 ms-2">
                  <h6 className="mb-0">
                    {details.paymentInfo?.remainingAmount} {t('CurrencyCode')}
                  </h6>
                </div>
              </div>
              <div className="d-flex align-items-center mb-2">
                <div className="flex-shrink-0">
                  <p className="text-muted mb-0">{t('PaymentMethod')}:</p>
                </div>
                <div className="flex-grow-1 ms-2">
                  <h6 className="mb-0">{t('' + details.paymentInfo?.paymentMethod)}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
