import React, { useContext, useEffect, useState } from 'react'
import { getSellerProductAction } from '../actions/product.actions'
import { Link, useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../store/store'
import { useTranslation } from 'react-i18next'
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface'
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component'
import { IOptions, IProduct } from '../interface/product.interface'
import { Helmet } from 'react-helmet'
import { Translation } from '../../../utility/helpers/translation.helper'
import { FormatDate } from '../../../utility/helpers/date.helper'
import { ROLE } from '../../../utility/enums/role.enum';
import RoleContext from '../../../provider/Profile.provider'

const ProductViewPage: React.FC<any> = () => {
	const assets = process.env.REACT_APP_ASSETS_URL
  const params = useParams()
  const dispatch = useAppDispatch()
  const userProfile = useContext(RoleContext)
  const [product, setProduct] = useState({} as IProduct)
  const { t } = useTranslation()

  const breadcrumb: Breadcrumb = {
    title: t('Products'),
    links: [
      { name: t('Dashboard'), path: '/' },
      { name: t('Products'), path: '/products/list' },
      { name: t('View'), path: '/products/view/' + params.id }
    ]
  }

  useEffect(() => {
    dispatch(getSellerProductAction(params.id!)).then((response: any) => {
      setProduct(response.payload.data)
    })
  }, [dispatch, params])

  return (
    <div className="container-fluid">
      {product && (
        <Helmet>
          <script src="/assets/js/pages/ecommerce-product-details.init.js" async></script>
        </Helmet>
      )}
      <div className="row">
        <BreadcrumbComponent breadcrumb={breadcrumb} />
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row gx-lg-5">
                <div className="col-xl-4 col-md-8 mx-auto">
                  <div className="product-img-slider sticky-side-div">
                    <div className="swiper product-thumbnail-slider p-2 rounded bg-light">
                      <div className="swiper-wrapper">
                        {product.gallary?.map((item: string) => (
                          <div key={item} className="swiper-slide">
                            <img src={assets + item} alt="" className="img-fluid d-block" />
                          </div>
                        ))}
                      </div>
                      <div className="swiper-button-next"></div>
                      <div className="swiper-button-prev"></div>
                    </div>
                    <div className="swiper product-nav-slider mt-2">
                      <div className="swiper-wrapper">
                        {product.gallary?.map((item: string) => (
                          <div key={item} className="swiper-slide">
                            <div className="nav-slide-item">
                              <img src={assets + item} alt="" className="img-fluid d-block" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-8">
                  <div className="mt-xl-0 mt-5">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h4>{Translation(product.name)}</h4>
                        <div className="hstack gap-3 flex-wrap">
                          <div className="text-muted">
                            {t('Seller')} : <span className="text-body fw-medium">{Translation(product.kitchen?.name)}</span>
                          </div>
                          <div className="vr"></div>
                          <div className="text-muted">
                            {t('Published')} : <span className="text-body fw-medium">{FormatDate(product.createdAt)}</span>
                          </div>
                        </div>
                      </div>
                      { userProfile.role?.type !== ROLE.ADMIN &&
												<div className="flex-shrink-0">
                        <div>
                          <Link
                            to={'/products/edit/' + params.id}
                            className="btn btn-light"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={t('Edit')}>
                            <i className="ri-pencil-fill align-bottom"></i>
                          </Link>
                        </div>
                      </div>
											}
                    </div>

                    <div className="d-flex flex-wrap gap-2 align-items-center mt-3">
                      <div className="text-muted fs-16">
                        <span className="mdi mdi-star text-warning"></span>
                      </div>
                      <div className="text-muted">{product.rating}</div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-lg-4 col-sm-6">
                        <div className="p-2 border border-dashed rounded">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm me-2">
                              <div className="avatar-title rounded bg-transparent text-success fs-24">
                                <i className="ri-money-dollar-circle-fill"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-1">{t('LowestPrice')}</p>
                              <h5 className="mb-0">{product.options ? product.options[0].price : 0}</h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-6">
                        <div className="p-2 border border-dashed rounded">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm me-2">
                              <div className="avatar-title rounded bg-transparent text-success fs-24">
                                <i className="ri-stack-fill"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-1">{t('DelivaryType')}</p>
                              <h5 className="mb-0">{t(product.delivaryType)}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-6">
                        <div className="mt-4">
                          <h5 className="fs-14">{Translation(product.attribute?.name)}</h5>
                          <div className="d-flex flex-wrap gap-2">
                            {product.options?.map((val: IOptions) => (
                              <div key={val.value}>
                                <label className="btn btn-soft-primary d-flex justify-content-center align-items-center">{val.value}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 text-muted">
                      <h5 className="fs-14">{t('Description')}</h5>
                      <p>{Translation(product.description)}</p>
                    </div>

                    <div className="product-content mt-5">
                      <h5 className="fs-14 mb-3">{t('ProductDescription')}</h5>
                      <nav>
                        <ul className="nav nav-tabs nav-tabs-custom nav-success" id="nav-tab" role="tablist">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              id="nav-speci-tab"
                              data-bs-toggle="tab"
                              href="apps-ecommerce-product-details.html#nav-speci"
                              role="tab"
                              aria-controls="nav-speci"
                              aria-selected="true">
                              {t('Specification')}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="nav-detail-tab"
                              data-bs-toggle="tab"
                              href="apps-ecommerce-product-details.html#nav-detail"
                              role="tab"
                              aria-controls="nav-detail"
                              aria-selected="false">
                              {t('Details')}
                            </a>
                          </li>
                        </ul>
                      </nav>
                      <div className="tab-content border border-top-0 p-4" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-speci" role="tabpanel" aria-labelledby="nav-speci-tab">
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row" style={{ width: '200px' }}>
                                    {t('Category')}
                                  </th>
                                  <td>{Translation(product.category?.name)}</td>
                                </tr>
                                <tr>
                                  <th scope="row">{Translation(product.attribute?.name)}</th>
                                  {product.options?.map((item: IOptions) => (
                                    <td key={item.value}>{item.value}</td>
                                  ))}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="nav-detail" role="tabpanel" aria-labelledby="nav-detail-tab">
                          <div dangerouslySetInnerHTML={{ __html: Translation(product.content)! }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductViewPage
