import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import RootComponent from './RootComponent';
import { persistor, store } from './store/store';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './provider/Auth.provider';
import { RoleProvider } from './provider/Profile.provider';
import { languageInitialization } from './utility/language';
import { LoadingProvider } from './provider/IsLoading.provider';

const App: React.FC = () => {
	languageInitialization();

	return (
		<BrowserRouter basename="/">
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<AuthProvider>
						<RoleProvider>
							<LoadingProvider>
								<RootComponent />
							</LoadingProvider>
						</RoleProvider>
					</AuthProvider>
				</PersistGate>
			</Provider>
		</BrowserRouter>
	);
};

export default App;
