import { _ } from 'gridjs-react'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component'
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface'
import GridjsComponent from '../../../components/gridjs/Gridjs.component'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum'
import RoleContext from '../../../provider/Profile.provider'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import { listSellerSpecialOrdersAction } from '../actions/special-orders.actions'
import { ISpecialOrder } from '../interfaces/special-order.interface'
import { useLoading } from '../../../provider/IsLoading.provider'
import { useNavigate } from 'react-router-dom'

export const SpecialOrdersListPage: React.FC<any> = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { specialOrders, status } = useAppSelector((state) => state.specialOrder)
  const userProfile = useContext(RoleContext)
  const navigate = useNavigate()
  const { setLoading } = useLoading()
  const breadcrumb: Breadcrumb = {
    title: t('SpecialOrders'),
    links: [
      { name: t('Dashboard'), path: '/' },
      { name: t('SpecialOrders'), path: '/special-orders/list' }
    ]
  }

  const special_columns = [
    {
      name: 'ID',
      width: '200px',
      sort: true,
      data: (e: ISpecialOrder) => {
        return _(
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <p className="text-muted mb-0">
                <a role='button' onClick={() => navigateToDetails('/special-orders/details/' + e._id)} className="text-body">
                  {e._id}
                </a>
              </p>
            </div>
          </div>
        )
      }
    },
    {
      name: t('BudgetRange'),
      sort: true,
      width: '120px',
      data: (e: ISpecialOrder) => {
        return _(`${e.budgetRange.from} ${t('CurrencyCode')} - ${e.budgetRange.to} ${t('CurrencyCode')}`)
      }
    },
    {
      name: t('PeopleNumber'),
      sort: true,
      width: '80px',
      data: (e: ISpecialOrder) => {
        return _(e.peopleNumber)
      }
    },
    {
      name: t('IsActive'),
      sort: true,
      width: '80px',
      data: (e: ISpecialOrder) => {
        return _(
          e.isActive ? (
            <span className="badge bg-success-subtle text-success text-uppercase">{t('Active')}</span>
          ) : (
            <span className="badge bg-danger-subtle text-danger text-uppercase">{t('InActive')}</span>
          )
        )
      }
    },
    {
      name: t('Status'),
      sort: true,
      width: '100px',
      data: (e: ISpecialOrder) => {
        return _(e.status)
      }
    },
    {
      name: t('Actions'),
      width: '110px',
      data: (e: ISpecialOrder) => {
        return _(
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
              <a role='button' onClick={() => navigateToDetails('/special-orders/details/' + e._id)} className="text-body">
                <i className="ri-eye-fill fs-16"></i>
              </a>
            </li>
          </ul>
        )
      }
    }
  ]

  useEffect(() => {
    if (status == REDUX_STATUS.SUCCEEDED || status == REDUX_STATUS.FAILED) {
      setLoading(false)
    }
  }, [setLoading, status])

  useEffect(() => {
    if (status == REDUX_STATUS.IDLE) {
      setLoading(true)
      dispatch(
        listSellerSpecialOrdersAction({
          page: 1,
          perPage: 12,
          orderBy: 'createdAt',
          orderDirection: SORT_DIRECTION.ASC
        })
      )
    }
  }, [dispatch, setLoading, status, userProfile])

  const navigateToDetails = (navLink: string) => {
    navigate(navLink)
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <BreadcrumbComponent breadcrumb={breadcrumb} />
      </div>

      <div className="row">
        <div className="col-md-12">
          <div>
            <div className="card">
              <div className="card-header border-0">
                <div className="row g-4">
                  <div className="col-sm">
                    <div className="d-flex justify-content-sm-end">
                      <div className="search-box ms-2">
                        <input type="text" className="form-control bg-light border-0" id="searchProductList" placeholder="Search Orders..." />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="tab-content text-muted">
                  <div id="table-product-list-published" className="table-card gridjs-border-none">
                    <GridjsComponent columns={special_columns} data={specialOrders} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
