import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component'
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface'
import { IUser } from '../interface/User.interface'
import { useAppDispatch } from '../../../store/store'
import { getProfileAction, updatePasswordAction, updateProfileAction, uploadImageAction } from '../action/profile.action'
import ProfileContext from '../../../provider/Profile.provider'

const ProfileEditPage: React.FC<any> = () => {
	const assets = process.env.REACT_APP_ASSETS_URL
  const [logoChange, setLogoChange] = useState(false)
  const { t } = useTranslation()
  const [profileForm, setProfileForm] = useState({} as IUser)
  const dispatch = useAppDispatch()
  const profile = useContext(ProfileContext)
  const [logoImage, setLogoImage] = useState('')
  const [logoImageHolder, setLogoImageHolder] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isEqual, setIsEqual] = useState(true)
  const [isMatch, setIsMatch] = useState(true)

  const regexPassword = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/

  const breadcrumb: Breadcrumb = {
    title: t('Products'),
    links: [
      { name: t('Dashboard'), path: '/' },
      { name: t('Profile'), path: '/profile/' },
      { name: t('Edit'), path: '/profile/edit/' }
    ]
  }

  useEffect(() => {
    if (profile && Object.keys(profile).length) {
      setProfileForm(profile)
      setLogoImageHolder(profile.photo)
    }
  }, [profile])

  const logoUploadHandler = (e: any) => {
    const file = e.target.files[0]
    setLogoImage(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      setLogoImageHolder(reader.result! as string)
    }
    reader.readAsDataURL(file)
    setLogoChange(true)
  }

  const comparePasswords = (pass: string | null, confirmPass: string | null): boolean => {
    if (pass !== confirmPass) {
      setIsEqual(false)
      return false
    } else {
      return true
    }
  }

  // const match = (pass : string , reg : RegExp) : boolean => {
  //     if(!reg.test(pass)) {
  //       setIsMatch(false)
  //       return false
  //     } else {
  //       return true
  //     }
  // }

  const changePasswordHandler = async () => {
    const isEqual: boolean = await comparePasswords(newPassword, confirmPassword)
    const isMatch: boolean = regexPassword.test(newPassword)
    if (!isMatch) setIsMatch(false)
    if (isMatch && isEqual) {
      dispatch(updatePasswordAction({ oldPassword, newPassword }))
    }
  }

  const submitHandler = () => {
    dispatch(updateProfileAction(profileForm)).then((Response: any) => {
      if (Response.payload.success) {
        dispatch(getProfileAction())
      }
    })
    if (logoChange) {
      const data = new FormData()
      data.append('file', logoImage)
      dispatch(uploadImageAction(data))
    }
  }

  const reset = () => {
    dispatch(getProfileAction())
  }

  const changeHandler = (event: any) => {
    const { name, value } = event.target
    switch (name) {
      case 'firstName': {
        setProfileForm((prevFormData) => ({ ...prevFormData, firstName: value }))
        break
      }
      case 'lastName': {
        setProfileForm((prevFormData) => ({ ...prevFormData, lastName: value }))
        break
      }
      case 'mobileNumber': {
        setProfileForm((prevFormData) => ({ ...prevFormData, mobileNumber: value }))
        break
      }
      default: {
        setProfileForm((prevFormData) => ({ ...prevFormData, [name]: value }))
        break
      }
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <BreadcrumbComponent breadcrumb={breadcrumb} />
      </div>

      <div className="row">
        <div className="col-xxl-3">
          <div className="card">
            <div className="card-body p-4">
              <div className="text-center">
                <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                  <img src={assets + logoImageHolder} className="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="user-profile-image" />
                  <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                    <input id="profile-img-file-input" type="file" className="profile-img-file-input" onChange={logoUploadHandler} />
                    <label htmlFor="profile-img-file-input" className="profile-photo-edit avatar-xs">
                      <span className="avatar-title rounded-circle bg-light text-body">
                        <i className="ri-camera-fill"></i>
                      </span>
                    </label>
                  </div>
                </div>
                <h5 className="fs-16 mb-1">{profile.firstName + ' ' + profile.lastName}</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xxl-9">
          <div className="card">
            <div className="card-header">
              <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-bs-toggle="tab" href="pages-profile-settings.html#personalDetails" role="tab">
                    <i className="fas fa-home"></i> {t('PersonalDetails')}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="pages-profile-settings.html#changePassword" role="tab">
                    <i className="far fa-user"></i> {t('ChangePassword')}
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body p-4">
              <div className="tab-content">
                <div className="tab-pane active" id="personalDetails" role="tabpanel">
                  <form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="firstnameInput" className="form-label">
                            {t('FirstName')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstnameInput"
                            placeholder="Enter your firstname"
                            name="firstName"
                            value={profileForm.firstName}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="lastnameInput" className="form-label">
                            {t('LastName')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastnameInput"
                            placeholder="Enter your lastname"
                            name="lastName"
                            value={profileForm.lastName}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="phonenumberInput" className="form-label">
                            {t('MobileNumber')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phonenumberInput"
                            placeholder="Enter your phone number"
                            name="mobileNumber"
                            value={profileForm.mobileNumber}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="emailInput" className="form-label">
                            {t('EmailAddress')}
                          </label>
                          <input type="email" className="form-control" id="emailInput" placeholder="Enter your email" disabled value={profileForm.email} />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="mb-3">
                          <label htmlFor="JoiningdatInput" className="form-label">
                            {t('JoiningDate')}
                          </label>
                          <input
                            value={profileForm.signUpDate?.split('T')[0]}
                            type="text"
                            className="form-control"
                            data-provider="flatpickr"
                            id="JoiningdatInput"
                            data-date-format="d M, Y"
                            data-deafult-date="24 Nov, 2021"
                            placeholder="Select date"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="hstack gap-2 justify-content-end">
                          <button type="button" className="btn btn-primary" onClick={submitHandler}>
                            Updates
                          </button>
                          <button type="button" className="btn btn-soft-success" onClick={reset}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="tab-pane" id="changePassword" role="tabpanel">
                  <form>
                    <div className="row g-2">
                      <div className="col-lg-4">
                        <div>
                          <label htmlFor="oldpasswordInput" className="form-label">
                            Old Password*
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="oldpasswordInput"
                            placeholder="Enter current password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div>
                          <label htmlFor="newpasswordInput" className="form-label">
                            New Password*
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="newpasswordInput"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                          {!isMatch && <div className="invalid-feedback">{t('PasswordNotValid')}</div>}
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div>
                          <label htmlFor="confirmpasswordInput" className="form-label">
                            Confirm Password*
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="confirmpasswordInput"
                            placeholder="Confirm password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <div className="invalid-feedback">{t('ConfirmPasswordError')}</div>
                          {!isEqual && <div className="invalid-password">{t('PasswordNotEqual')}</div>}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="text-end">
                          <button type="button" className="btn btn-primary" onClick={changePasswordHandler}>
                            Change Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileEditPage
