import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

export const firebaseConfig = {
	apiKey: "AIzaSyDQaln_RugK6NECxjz5yRPKMglmkh9xnto",
	authDomain: "akla-beity.firebaseapp.com",
	projectId: "akla-beity",
	storageBucket: "akla-beity.appspot.com",
	messagingSenderId: "551736544959",
	appId: "1:551736544959:web:d3b0fc96f87687ec0a000a",
	measurementId: "G-KP9BRMKQ5Z",
	vapidKey: "BN0YP_GgmXVlrNjR2wQHBaaTZV2lV_BwUBNdZf85lxQZpWpG60hXXCIec0i0UbqCKWNvwiTb5pP-CFbV44agTkQ"
};

export const FIREBASE_VAPID_KEY = firebaseConfig.vapidKey;

const app = initializeApp( firebaseConfig );
const messaging = getMessaging( app );

export const requestForToken = () => {
	return getToken( messaging, { vapidKey: FIREBASE_VAPID_KEY } )
		.then( ( currentToken ) => {
			if ( currentToken ) {
				return currentToken;
			} else {
				alert(
					"No registration token available. Request permission to generate one."
				);
				return null;
			}
		} )
		.catch( ( err ) => {
			alert( "An error occurred while retrieving token - " + err );
			return null;
		} );
};

export const onReciveMessage = () => {
	return onMessage( messaging, ( { notification } ) => {
		new Notification( notification.title, {
			body: notification.body,
			icon: notification.icon,
		} );
	} );
};
