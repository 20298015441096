import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { Translation } from '../../../utility/helpers/translation.helper';
import { IOfferItem, ISpecialOffer } from '../interfaces/special-order.interface';
import { addSpecialOfferAction, getSpecialOrderDetailsAction } from '../actions/special-orders.actions';
import { Helmet } from 'react-helmet';
import { FormatDate } from '../../../utility/helpers/date.helper';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';

export const SpecialOrdersDetailsPage: React.FC<any> = () => {
	const assets = process.env.REACT_APP_ASSETS_URL;
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { details, status } = useAppSelector((state) => state.specialOrder);
	const params = useParams();

	const [formData, setFormData] = useState({
		requireDeposit: false,
		deposit: 0,
		total: 0,
		items: [
			{
				item: '',
				weight: '',
				price: 0
			}
		]
	} as ISpecialOffer);

	const breadcrumb: Breadcrumb = {
		title: t('SpecialOrder'),
		links: [
			{ name: t('Dashboard'), path: '/' },
			{ name: t('SpecialOrders'), path: '/special-orders/list/' },
			{ name: t('SpecialOrder'), path: '/special-orders/details/' + params.id }
		]
	};

	useEffect(() => {
		if (status === REDUX_STATUS.IDLE) {
			dispatch(getSpecialOrderDetailsAction(params.id!));
		}
	}, [dispatch, params, status]);

	const addItemHandler = () => {
		setFormData((preValue) => ({
			...preValue,
			items: [
				...preValue.items,
				{
					item: '',
					weight: '',
					price: 0
				}
			]
		}));
	};

	const changeHandler = (event: React.ChangeEvent<any>, index?: number) => {
		const { name, value } = event.target;
		switch (name) {
			case 'requireDeposit': {
				setFormData((prevFormData) => ({ ...prevFormData, [name]: JSON.parse(value) }));
				break;
			}
			case 'deposit': {
				setFormData((prevFormData) => ({ ...prevFormData, [name]: Number(value) }));
				break;
			}
			case 'offerItem': {
				setFormData((prevFormData) => ({
					...prevFormData,
					items: [
						...prevFormData.items.map((offerItem: IOfferItem, i: number) => {
							if (i == index) {
								offerItem.item = value;
							}
							return offerItem;
						})
					]
				}));
				break;
			}
			case 'weight': {
				setFormData((prevFormData) => ({
					...prevFormData,
					items: [
						...prevFormData.items.map((offerItem: IOfferItem, i: number) => {
							if (i == index) {
								offerItem.weight = value;
							}
							return offerItem;
						})
					]
				}));
				break;
			}
			case 'price': {
				setFormData((prevFormData) => ({
					...prevFormData,
					items: [
						...prevFormData.items.map((offerItem: IOfferItem, i: number) => {
							if (i == index) {
								offerItem.price = Number(value);
							}
							return offerItem;
						})
					]
				}));
				break;
			}
			default: {
				setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
				break;
			}
		}
	};

	const calcTotal = (items: IOfferItem[]): number => {
		let total = 0;
		for (const item in items) {
			total += items[item].price;
		}
		return total;
	};

	const addOfferHandler = async () => {
		const totalPrice = await calcTotal(formData.items);
		dispatch(addSpecialOfferAction({ id: params.id!, data: { ...formData, total: totalPrice } }));
	};

	return (
		<div className="container-fluid">
			<Helmet>{details && <script src="/assets/js/pages/gallery.init.js" async></script>}</Helmet>
			<div className="row">
				<BreadcrumbComponent breadcrumb={breadcrumb} />
			</div>

			<div className="row">
				<div className="col-xl-9">
					<div className="card">
						<div className="card-header">
							<div className="d-flex align-items-center">
								<h5 className="card-title flex-grow-1 mb-0">
									{t('SpecialOrder')} #{details._id}
								</h5>
								<div className="dropdown ml-2">
									<button
										type="button"
										className="btn btn-success add-btn"
										data-bs-toggle="modal"
										id="create-btn"
										data-bs-target="#addOffer"
										disabled={!details.isActive || details.offers.length > 0}>
										<i className="ri-add-line align-bottom me-1"></i> {t('AddOffer')}
									</button>
								</div>
							</div>
						</div>
						<div className="card-body">
							<div className="table-responsive table-card">
								<table className="table table-borderless mb-0">
									<tbody>
										<tr>
											<td>{t('OrderDescription')}:</td>
											<td className="text-start">{details.description}</td>
										</tr>
										<tr>
											<td>{t('PeopleNumber')}:</td>
											<td className="text-start">{details.peopleNumber}</td>
										</tr>
										<tr>
											<td>{t('BudgetRange')}:</td>
											<td className="text-start">
												{details.budgetRange?.from} {t('CurrencyCode')} - {details.budgetRange?.to} {t('CurrencyCode')}
											</td>
										</tr>
										<tr>
											<td>{t('DelivaryDate')}:</td>
											<td className="text-start">{FormatDate(details.delivaryDate)}</td>
										</tr>
										{details.images?.length > 0 && (
											<tr>
												<td>{t('Images')}:</td>
												<td className="text-start">
													<div className="row gallery-wrapper">
														{details.images?.map((image: string) => (
															<div className="element-item col-xxl-3 col-xl-4 col-sm-6 project designing development" key={image}>
																<div className="gallery-box card">
																	<div className="gallery-container">
																		<a className="image-popup" href={image} title="">
																			<img className="gallery-img img-fluid mx-auto" src={image} alt="aklabeity" />
																		</a>
																	</div>
																</div>
															</div>
														))}
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					{details.offers?.length > 0 && (
						<div className="card">
							<div className="card-header">
								<div className="d-flex align-items-center">
									<h5 className="card-title flex-grow-1 mb-0">{t('MyOffer')}</h5>
								</div>
							</div>
							<div className="card-body">
								<div className="table-responsive table-card">
									<table className="table table-nowrap align-middle table-borderless mb-0">
										<thead className="table-light text-muted text-start">
											<tr>
												<th scope="col">{t('Item')}</th>
												<th scope="col">{t('Description')}</th>
												<th scope="col">{t('Price')}</th>
											</tr>
										</thead>
										<tbody className="text-start">
											{details.offers && (
												<>
													{details.offers[0]?.items.map((offerItem: IOfferItem) => (
														<tr key={offerItem.item}>
															<td>{offerItem.item}</td>
															<td>{offerItem.weight}</td>
															<td>
																{offerItem.price} {t('CurrencyCode')}
															</td>
														</tr>
													))}

													<tr className="border-top border-top-dashed">
														<td colSpan={2}></td>
														<td colSpan={1} className="fw-medium p-0">
															<table className="table table-borderless mb-0">
																<tbody>
																	<tr>
																		<td>{t('Total')} :</td>
																		<td className="text-end">
																			{calcTotal(details.offers[0]?.items)} {t('CurrencyCode')}
																		</td>
																	</tr>
																	<tr>
																		<td>{t('RequireDeposit')} :</td>
																		<td className="text-end">{details.offers[0]?.requireDeposit ? t('Yes') : t('No')}</td>
																	</tr>
																	<tr>
																		<td>{t('Deposit')} :</td>
																		<td className="text-end">
																			{details.offers[0]?.deposit} {t('CurrencyCode')}
																		</td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="col-xl-3">
					<div className="card">
						<div className="card-header">
							<div className="d-flex">
								<h5 className="card-title flex-grow-1 mb-0">{t('CustomerDetails')}</h5>
							</div>
						</div>
						<div className="card-body">
							<ul className="list-unstyled mb-0 vstack gap-3">
								<li>
									<div className="d-flex align-items-center">
										<div className="flex-shrink-0">
											<img src={assets + details.customer?.photo} alt="" className="avatar-sm rounded" />
										</div>
										<div className="flex-grow-1 ms-3">
											<p className="text-muted mb-0">{t('CustomerDetails')}</p>
											<h6 className="fs-14 mb-1">
												{details.customer?.firstName} {details.customer?.lastName}
											</h6>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div className="card">
						<div className="card-header">
							<h5 className="card-title mb-0">
								<i className="ri-map-pin-line align-middle me-1 text-muted"></i> {t('DelivaryAddress')}
							</h5>
						</div>
						<div className="card-body">
							<ul className="list-unstyled vstack gap-2 fs-13 mb-0">
								<li className="fw-medium fs-14">Governerate : {Translation(details.address?.governorate?.name)}</li>
								<li>City : {Translation(details.address?.city?.name)}</li>
								<li>Street : {details.address?.street}</li>
								<li>Building Number : {details.address?.buildingNumber}</li>
								<li>Floor : {details.address?.floor}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="addOffer" tabIndex={-1} aria-labelledby="addOfferLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header bg-light p-3">
							<h5 className="modal-title" id="addOfferLabel">
								{t('AddOffer')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"></button>
						</div>
						<form className="tablelist-form" autoComplete="off">
							<div className="modal-body">
								<div className="row">
									<div className="col-md-6">
										<label className="form-label">{t('RequireDeposit')}</label>
										<div className="mt-4 mb-4 mt-lg-0">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input"
													type="radio"
													name="requireDeposit"
													id="true"
													value="true"
													checked={formData.requireDeposit}
													onChange={(e) => changeHandler(e)}
												/>
												<label className="form-check-label" htmlFor="true">
													{t('Yes')}
												</label>
											</div>
											<div className="form-check form-check-inline">
												<input
													className="form-check-input"
													type="radio"
													name="requireDeposit"
													id="false"
													value="false"
													checked={!formData.requireDeposit}
													onChange={(e) => changeHandler(e)}
												/>
												<label className="form-check-label" htmlFor="false">
													{t('No')}
												</label>
											</div>
										</div>
									</div>
									{formData.requireDeposit && (
										<div className="col-md-6">
											<div className="mb-3">
												<label className="form-label" htmlFor="deposit">
													{t('Deposit')}
												</label>
												<div className="input-group">
													<input
														type="number"
														className="form-control"
														id="deposit"
														name="deposit"
														onChange={changeHandler}
														placeholder={t('Deposit')}
														required
													/>
													<span className="input-group-text" id="deposit">
														%
													</span>
												</div>
											</div>
										</div>
									)}
								</div>
								{formData.items.map((offer: IOfferItem, i: number) => {
									return (
										<div key={i}>
											<div className="row">
												<div className="col-md-6">
													<div className="mb-3">
														<label htmlFor={'offerItem' + i} className="form-label">
															{t('Item')}
														</label>
														<input
															type="text"
															id={'offerItem' + i}
															name="offerItem"
															onChange={(e) => changeHandler(e, i)}
															className="form-control"
															placeholder="Enter Item Name"
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="mb-3">
														<label htmlFor={'price' + i} className="form-label">
															{t('Price')}
														</label>
														<input
															type="number"
															id={'price' + i}
															name="price"
															onChange={(e) => changeHandler(e, i)}
															className="form-control"
															placeholder="Item Price"
															required
														/>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-12">
													<div className="mb-3">
														<label htmlFor={'weight' + i} className="form-label">
															{t('Description')}
														</label>
														<textarea
															rows={4}
															className="form-control"
															id={'weight' + i}
															name="weight"
															onChange={(e) => changeHandler(e, i)}
															placeholder="Item Description"
															required
														/>
													</div>
												</div>
											</div>
										</div>
									);
								})}
								<div className="mb-3">
									<button type="button" id="add-item" className="btn btn-soft-secondary fw-medium ml-auto" onClick={addItemHandler}>
										<i className="ri-add-fill me-1 align-bottom"></i> {t('AddItem')}
									</button>
								</div>
							</div>
							<div className="modal-footer">
								<div className="hstack gap-2 justify-content-end">
									<button type="button" className="btn btn-primary" data-bs-dismiss="modal" id="add-btn" onClick={addOfferHandler}>
										{t('AddOffer')}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
