import React from "react";
import { Grid } from 'gridjs-react';
import { GridJs } from "./gridjs.interface";

const GridjsComponent: React.FC<GridJs> = ({columns, data}) => {
  
  return(
    <Grid
    data={data}
    columns={columns}
  />
  )
}

export default GridjsComponent;
